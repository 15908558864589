//
// style for main search
//

$input-height: 60px;

.searchFormCenter {
	@media print, screen and (min-width: 40em)  {
        display: flex;
		justify-content: center;
		flex-direction: column;
		height: 100%;
	}
	@media print, screen and (min-width: 64em) {
		padding-right: 2rem;
	}
}

.searchFormHead {
	padding: 5vh 5vw;
	@media print, screen and (min-width: 40em)  {
        padding: 4vh 0;
    }
}

.searchFormTitle {
	margin: 0 0 2rem 0;
    font-size: 3rem;
    line-height: 1;
	font-weight: 700;
	@media print, screen and (min-width: 40em) {
        font-size: 3.5rem;
    }
}

.searchInputKeyWord,
.searchInputCity,
.searchInputSelect {
	width: 100%;
	padding: 0 1.25rem;
	margin: 0 0 -.625rem 0;
	height: $input-height;
	color: #333;
    vertical-align: middle;
    background-color: #fff;
	border: 4px solid #333;
	
	&:focus {
		outline: none;
		outline-offset: 0;
	}
	@media print, screen and (min-width: 40em)  {
		margin: 0;
    }
}

.searchInputSelect {
	-moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
	appearance:none;
	background-repeat: no-repeat;
    background-position: 95% 50%;
    background-size: 25px;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iYmxhY2siIHdpZHRoPSIxOHB4IiBoZWlnaHQ9IjE4cHgiPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMTYuNTkgOC41OUwxMiAxMy4xNyA3LjQxIDguNTkgNiAxMGw2IDYgNi02eiIvPjwvc3ZnPg==");
}

.searchMessage {
	display: block;
	width: 100%;
	margin: 1rem 0;
    font-size: 1.25rem;
	line-height: 1.4;
	font-weight: 500;
}